import React from "react"

import Layout from "../components/layout"
import Head from "../components/header/head"

// Initialize and add the map
function initMap(): void {
  // The location of Uluru
  // const uluru = { lat: -25.344, lng: 131.031 }
  // // The map, centered at Uluru
  // const map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
  //   zoom: 4,
  //   center: uluru,
  // })
  // // The marker, positioned at Uluru
  // const marker = new google.maps.Marker({
  //   position: uluru,
  //   map: map,
  // })
}

declare global {
  interface Window {
    initMap: () => void
  }
}
// window.initMap = initMap;

const LocationsPage = () => {
  return (
    <Layout headerColor="" headerPageName="Locations" headerSubTitle="" headerTitle="">
      <Head title="Locations" description="Locations" />
      test
    </Layout>
  )
}
export default LocationsPage
